import {
  Component,
  HostListener,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { TrackingService } from "src/app/services/tracking/tracking.service";
import * as $ from "jquery";
import { CryptoservService } from "src/app/services/cryposerv/cryptoserv.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-paginacion",
  templateUrl: "./paginacion.component.html",
  styleUrls: ["./paginacion.component.scss"],
})
export class PaginacionComponent implements OnInit {
  @Input() page: number;
  @Input() totalPages: number;
  @Input() cantPreg: number;
  @Output() prevPage = new EventEmitter<void>();
  @Output() nextPage = new EventEmitter<void>();

  modulo: any;
  tracedPage: any;
  link: any;
  numberModulo: number;
  nombreModulo = "Sala cuna"

  constructor(
    private trackingService: TrackingService,
    private router: Router,
    private cryptoServ: CryptoservService
  ) {}

  tipoUsuario=""

  ngOnInit(): void {
    this.tracedPage = window.location.href;
    this.modulo = window.location.pathname.split("/")[2].replace(/-/gi, " ");
    //this.modulo = window.location.pathname.split('/')[2].replace(/-/gi, ' ');
    this.link = `modulo/${this.modulo.replace(/ /gi, "-")}`;
    var nomModulo = window.location.pathname.split("/")[2];
    if (nomModulo == "MANUAL-DE-AUTO-INSTRUCCION") {
      this.numberModulo = 0;
    } else if (nomModulo == "Normas-Conceptos") {
      this.numberModulo = 1;
      } else if (nomModulo == "Peligros") {
        this.numberModulo = 2;
    } else if (nomModulo == "BPM" || nomModulo == "BPMJARDINES") {
      this.numberModulo = 3;
    } else if (nomModulo == "Tecnicas") {
      this.numberModulo = 4;
    } else {
      this.numberModulo = 0;
    }
    var temp = localStorage.getItem("modulo");
    var moduloLocalNumber = this.cryptoServ.decrypted(temp);
    //var moduloLocalNumber = localStorage.getItem('modulo');
    var temp = localStorage.getItem("rut");
    var rutUser = this.cryptoServ.decrypted(temp);
    const jsonObject = JSON.parse(localStorage.getItem("usuario"));
    this.tipoUsuario=this.cryptoServ.decrypted(jsonObject.tipoUsuario);

    console.log("Este es el tipoUsuario: ",this.tipoUsuario)
    //var rutUser = localStorage.getItem('rut');
    if (parseInt(moduloLocalNumber) != this.numberModulo) {
      if (this.numberModulo > parseInt(moduloLocalNumber)) {
        this.page = 1;
        var encrypt = this.cryptoServ.encrypted(this.numberModulo);
        localStorage.setItem("modulo", encrypt);
        //localStorage.setItem('modulo', JSON.stringify(this.numberModulo));
        encrypt = this.cryptoServ.encrypted(1);
        localStorage.setItem("pagina", encrypt);
        //localStorage.setItem('pagina', JSON.stringify(1));
        this.updateInfoBd(rutUser, this.numberModulo, 1);
      }
    }
  }

  prevPageClicked() {
    console.log("a");
    this.prevPage.emit();
  }

  nextPageClicked() {
    console.log("b");
    this.validSavePag();
    this.nextPage.emit();
  }

  primeraPagina(): boolean {
    if (this.page === 1) {
      return true;
    } else {
      return false;
    }
  }

  validFirstPag(): any {
    if (this.page == 1) {
      return true;
    }
    return false;
  }

  validFinModulo(): any {
    if((this.page - this.totalPages) == this.cantPreg + 1){
      return true
    }
    return false
  }

  salir(){
    this.router.navigate(['/curso']);
  }


  validLastPag(): any {
    //console.log((this.page - this.totalPages));
    let pregunta='';
    let localObtenido=null;
    //console.log('modulo: '+this.numberModulo)
    this.tipoUsuario='JUNAEB'// CAMBIAR ESTO PARA JUNAEB Y PARA JARDINES <---- SEBA ACUERDATE
    if(this.numberModulo==3 && this.tipoUsuario=='JUNAEB'){
      console.log('page: '+this.page)
      switch(this.page){
        case 15:
          pregunta = "3-1";
          localObtenido = localStorage.getItem(pregunta);
          if (localObtenido != null && localObtenido != undefined && localObtenido != "") {
            return true;
          }         
          return false;
        break;
        case 43:
           pregunta = "3-2";
           localObtenido = localStorage.getItem(pregunta);
          if (localObtenido != null && localObtenido != undefined && localObtenido != "") {
            return true;
          }         
          return false;
          break;
        case 70:
            pregunta = "3-4";
            localObtenido = localStorage.getItem(pregunta);
           if (localObtenido != null && localObtenido != undefined && localObtenido != "") {
             return true;
           }         
           return false;
           break;
        case 71:
           return false;
           break;
      }
      return true;
    }else if(this.numberModulo==3){
      console.log('page: '+this.page)
      switch(this.page){
        case 15:
          pregunta = "3-1";
          localObtenido = localStorage.getItem(pregunta);
          if (localObtenido != null && localObtenido != undefined && localObtenido != "") {
            return true;
          }         
          return false;
        break;
        case 47:
           pregunta = "3-2";
           localObtenido = localStorage.getItem(pregunta);
          if (localObtenido != null && localObtenido != undefined && localObtenido != "") {
            return true;
          }         
          return false;
          break;       
           return false;
           break;
        case 74:
            pregunta = "3-3";
            localObtenido = localStorage.getItem(pregunta);
           if (localObtenido != null && localObtenido != undefined && localObtenido != "") {
             return true;
           }         
           return false;
           break;
        case 75:
           return false;
           break;
      }
      return true;     
    }
    if (this.page > this.totalPages) {
      const pregunta = this.numberModulo.toString() + "-" + (this.page - this.totalPages).toString();
      const localObtenido = localStorage.getItem(pregunta);
      if (localObtenido != null && localObtenido != undefined && localObtenido != "") {
        return true;
      }
    } else {
      return true
    }
    return false;
  }

  validSavePag() {
    console.log("infooo guardar alfa");
    var temp = localStorage.getItem("modulo");
    var lastModule = this.cryptoServ.decrypted(temp);
    // var lastModule = localStorage.getItem('modulo');
    var temp = localStorage.getItem("pagina");
    var lastPagina = this.cryptoServ.decrypted(temp);
    // var lastPagina = localStorage.getItem('pagina');
    var temp = localStorage.getItem("rut");
    var rutUser = this.cryptoServ.decrypted(temp);
    // var rutUser = localStorage.getItem('rut');
    console.log("infooo guardar");
    console.log("this.numberModulo: " + this.numberModulo);
    console.log("lastModule: " + lastModule);
    console.log("lastPagina: " + lastPagina);
    console.log("this.page + 1: " + (this.page + 1));
    console.log("--------------------------");
    if (
      this.numberModulo == parseInt(lastModule) &&
      (this.page + 1) > parseInt(lastPagina)
    ) {
      var encrypt = this.cryptoServ.encrypted(this.page + 1);
      localStorage.setItem("pagina", encrypt);
      // localStorage.setItem('pagina', JSON.stringify(this.pagina));
      this.updateInfoBd(rutUser, this.numberModulo, this.page + 1);
    }
  }

  async updateInfoBd(rut: string, modulo: number, pagina: number) {
    await this.trackingService.setAllTracking(rut, modulo, pagina);
  }
}
