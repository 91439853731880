<app-toolbar></app-toolbar>
<div
  class=" container-curso justify-content-center align-items-center fondoPrincipal"
>
  <br />
  <div
    class="bg-gray-200 bg-opacity-75 max-w-90 mx-auto py-3 rounded-lg font-bold p-8 px-10"
  >
    <h4 class="text-center">PRESENTACIÓN DEL CURSO</h4>
    <h1 class="text-center">Sala Cuna</h1>
  </div>
  <div class="container p-1">
    <h2 class="text-center">Programación del curso:</h2>
    <mat-accordion class="accordion-curso">
      <!-- INICIO DE SECCION 1 -->
      <BR />
      <h3 class="text-center">Curso</h3>
      <BR />
      <!-- INICIO MODULO 1 -->
      <!-- caso exitoso -->
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="m-2"
        [class.colorGreen]="isComple(1)"
        [disabled]="!isHabilitado(1)"
        id="section1"
      >
        <mat-expansion-panel-header class="h-full py-1">
          <mat-panel-title>
            <div class="text-sm">
              1. SALA CUNA
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list [multiple]="false">
          <mat-list-item *ngFor="let pagina of modulo1; let i = index">
            <a
              matLine
              href="/modulo/Normas-Conceptos#{{ i + 1 }}"
              [disabled]="!isValidPagina(i + 1)"
            >
              1.{{ i + 1 }} - {{ pagina }}
            </a>
            <mat-icon>play_circle_outline</mat-icon>
            {{ setPaginasModulo1(i + 1) }}
          </mat-list-item>
        </mat-selection-list>
      </mat-expansion-panel>

    </mat-accordion>
    <div class="w-full px-2">
      <button mat-raised-button class="w-full" color="primary" (click)="iniciarExamen()" [disabled]="validarCursoRealizado() || !isHabilitado(2)">
          Iniciar evaluación
        </button>
    </div>
    <br />
  </div>

  <div class="container p-2" *ngIf="validCursoCompleto()">
    <div
      class="container bg-card shadow rounded-2xl p-3 bg-orange-600 text-center mx-auto text-xl"
    >
      <div class="text-center">Código Término Curso</div>
      <div>
        <button mat-raised-button color="primary" (click)="openDialog()">
            Ver Codigo
          </button>
      </div>
    </div>
  </div>

  <div
    class="bg-gray-200 bg-opacity-75 max-w-90 mx-auto py-3 rounded-lg font-bold p-8 px-10 mb-3"
  >
    <div class="text-center">Rut: {{ rutPersona }}</div>
      <div class="text-center">Nombre: {{ nombrePersona }}</div>
  </div>

  <div
    *ngIf="validAdmin()"
    class="container p-4 colorGris d-flex flex-column justify-content-center align-items-center"
  >
    <h2 class="text-center">Supervisor</h2>
    <hr />
    <div class="p-1">
      <button mat-raised-button color="primary" (click)="export()" [disabled]="isCargando">
        Exportar avance
      </button>
    </div>
    <div class="p-1">
      <button mat-raised-button color="primary" (click)="descargarExamen()" [disabled]="isCargando">
        Exportar evaluación
      </button>
    </div>
    <div class="px-3 text-center font-bold text-2xl " *ngIf="isCargando">
      Descargando
      <div class="flex justify-center pr-10 items-center w-full"> <!-- Para alinear a la derecha -->
        <img class="text-center w-full items-center" src="assets/images/cargaFlujo.webp" style="max-height: 50px; max-width: 50px;" /> 
      </div>
    </div>
  </div>
  <br />
</div>
